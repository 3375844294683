import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEnvironment } from '../../config/api.config';

@Injectable({
  providedIn: 'root',
})
export class ClientService {

  constructor(private http: HttpClient) {
  }

  addNewPhone(idClient: string, phone: string, title: string) {
    return this.http.post(`${apiEnvironment.url}/client/phone`, {
      id: idClient,
      phone,
      title
    });
  }

  updatePhone(idClient: string, originPhone: string, newPhone: string, title: string) {
    return this.http.put(`${apiEnvironment.url}/client/phone/update`, {
      id: idClient,
      originPhone,
      newPhone,
      title
    });
  }

}
