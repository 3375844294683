import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig, FieldSelected } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-input',
  template: `
    <div
      *ngIf="config"
      class="form-group"
      [formGroup]="group">
      <div class="d-flex">
        <label class="form-label">{{config.label}}
          <strong class="text-danger" *ngIf="config.required">*</strong>
        </label>
        <app-management-field *ngIf="editMode" [config]="config"
                              (sendField)="sendFieldFunc($event)"></app-management-field>
      </div>
      <input type="{{config.inputType || 'text'}}"
             data-name="{{config.name}}"
             class="form-control"
             autocomplete="off"
             maxlength="{{config.maxlength}}"
             [hidden]="config.hide"
             [ngClass]="isSubmit && field.errors?'is-invalid':''"
             [attr.placeholder]="config.placeholder"
             [formControlName]="config.name">
      <app-form-validate *ngIf="isSubmit && field && field.errors" [field]="field"></app-form-validate>
    </div>`
})
export class FormInputComponent implements Field, OnInit {
  // @ts-ignore
  config: FieldConfig;
  // @ts-ignore
  group: FormGroup;
  isSubmit = false;
  // @ts-ignore
  field: AbstractControl;
  // @ts-ignore
  editMode = false;

  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.config) {
      this.field = this.group?.controls[this.config.name];
    }
  }

  sendFieldFunc(nameField: FieldSelected) {
    this.sendField.emit(nameField)
  }

}
