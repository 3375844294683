import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
    permissions: [ 'ass' ]
  },
  {
    id: 2,
    label: 'Administración',
    icon: 'ri-briefcase-line',
    subItems: [
      {
        id: 3,
        label: 'Usuarios',
        link: '/management/users',
        parentId: 2,
        permissions: [ 'adminAuthPermission:1' ]
      },
      {
        id: 4,
        label: 'Roles',
        link: '/management/roles',
        parentId: 2,
        permissions: [ 'adminAuthPermission:1' ]
      },
      {
        id: 5,
        label: 'Grupos',
        link: '/management/groups',
        parentId: 2,
        permissions: [ 'adminAuthPermission:1' ]
      },
    ],
    permissions: [ 'adminAuthPermission:1' ]
  },
  {
    id: 6,
    label: 'Configuración',
    icon: 'ri-settings-3-line',
    subItems: [
      {
        id: 7,
        label: 'Catálogos',
        link: '/configuration/catalogs',
        parentId: 6,
        permissions: [ 'adminCatalogPermission:1' ]
      },
    ],
    permissions: [ 'adminCatalogPermission:1' ]
  },
  {
    id: 8,
    label: 'Segmento',
    icon: 'ri-stack-line',
    link: '/segment/segment',
    permissions: [ 'adminCampaignPermission:1' ]
  },
  {
    id: 9,
    label: 'Campaña',
    icon: 'ri-building-line',
    link: '/campaign',
    permissions: [ 'adminCampaignPermission:1' ]
  },
  {
    id: 10,
    label: 'Gestionar Cobranzas',
    icon: 'ri-exchange-dollar-line',
    link: '/collection/collection-campaign',
    permissions: [ 'createCollectionManagementPermission:1' ]
  },
  {
    id: 10,
    label: 'Cobranzas',
    icon: 'ri-list-check',
    link: '/collection',
    permissions: [ 'createCollectionManagementPermission:1' ]
  },
  {
    id: 11,
    label: 'Reportes',
    icon: 'ri-file-pdf-line',
    subItems: [
      {
        id: 12,
        label: 'Historial de Gestiones',
        link: '/report/collection-management',
        parentId: 11,
        permissions: [ 'adminCatalogPermission:1' ]
      },
      {
        id: 13,
        label: 'Gestiones / Pagos',
        link: '/report/management-payment',
        parentId: 11,
        permissions: [ 'adminCatalogPermission:1' ]
      },
      {
        id: 14,
        label: 'Gestiones Diarias',
        link: '/report/collection-diary',
        parentId: 11,
        permissions: [ 'adminCatalogPermission:1' ]
      },
      {
        id: 15,
        label: 'Base Madre',
        link: '/report/collection-base-campaign',
        parentId: 11,
        permissions: [ 'adminCatalogPermission:1' ]
      },
      {
        id: 16,
        label: 'Avances por Agente',
        link: '/report/agent-advance',
        parentId: 11,
        permissions: [ 'adminCatalogPermission:1' ]
      },
    ],
    permissions: [ 'adminCatalogPermission:1' ]
  },

];
