import { Component, OnInit, inject, Input, Output, EventEmitter } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-range-datepicker-input',
  template: `
    <div class="row row-cols-sm-auto">
      <div class="col-12">
        <div class="input-group">
          <input
            name="datepicker"
            class="form-control"
            ngbDatepicker
            [value]="dataView"
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
            tabindex="-1"
          />
          <button class="btn btn-info" (click)="datepicker.toggle()"
                  type="button">
            <i class="ri-calendar-2-line"></i>
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="dp-hidden position-absolute">
          <div class="input-group">
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >{{ date.day }}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [ './range-datepicker-input.component.scss' ]
})

export class RangeDatepickerInputComponent implements OnInit {

  @Output() setRange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  dataView: any = null;

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.dataView = this.formatter.format(this.fromDate) + ' -> ' + this.formatter.format(this.toDate);
      this.setRange.emit({ from: this.formatter.format(this.fromDate), to: this.formatter.format(this.toDate) });
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  ngOnInit() {
  }
}
