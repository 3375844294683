import { Component, Input, OnInit } from "@angular/core";
import { ToastService } from "../../../core/services/toast.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CallService } from "../../../core/services/call.service";

@Component({
  selector: 'app-call-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title"><i class="las la-phone text-primary fs-2 mr-2"></i> Registro de llamada</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
              (click)="activeModal.close('exit')"></button>
    </div>
    <div class="modal-body">
      <div class="mb-3 col container">
        <div class="row mb-3">
          <div id="contact-existing-list">
            <p><b>Usuario:</b> {{fullName}}</p>
            <p><b>Fecha y hora:</b> {{date}}</p>
            <p><b>Duración:</b> {{duration}}s</p>
            <p><b>Teléfono:</b> {{phone}}</p>
            <p><b>Agente:</b> {{agentName}}</p>
            <p><b>Estado de llamada:</b> {{statusName}}</p>
            <p><b>Grabación:</b></p>
            <div class="d-flex justify-content-center">
              <div *ngIf="loading">
                <div class="progress p-0 me-2" style="width: 30vh">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                       aria-valuenow="75"
                       aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                </div>
              </div>
              <audio *ngIf="!loading && (callUrl != '')" controls [src]="callUrl" preload="auto" id="audio_player"
                     type="audio/mp3">
                El navegador no puede reproducir el audio.
              </audio>
            </div>
          </div>
          <div *ngIf="callUrl == '' && !loading">
            Llamada no encontrada
          </div>
        </div>
      </div>
    </div>
  `,
  providers: [MatSnackBar]
})

export class CallModalComponent implements OnInit {


  @Input() fullName!: string;
  @Input() agentName!: string;
  @Input() date!: string;
  @Input() duration!: string;
  @Input() phone!: string;
  @Input() statusName: string = 'Intento de Marcado';
  @Input() key!: string;

  callUrl = '';
  loading = false;

  constructor(private callService: CallService,
              public toastService: ToastService,
              public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.downloadCall();
  }

  downloadCall() {
    this.loading = true;
    this.callService.downloadCall(this.key).subscribe({
      next: (data) => {
        this.loading = false;
        this.callUrl = data.url;
      },
      error: (err) => {
        this.loading = false;
        this.toastService.show(err, {classname: 'bg-danger text-white', delay: 2000});
      }
    })
  }
}
