import { Component, OnInit, Input } from '@angular/core';
import { ValidateMessages } from '../../models/validate-messages';

@Component({
  selector: 'app-form-validate',
  template: `
    <div class="invalid-feedback" style="display: block">{{message}}</div>`
})
export class FormValidateComponent implements OnInit {
  @Input()
    // @ts-ignore
  field;
  // @ts-ignore
  message;
  validateMessages;

  constructor() {
    this.validateMessages = new ValidateMessages();
  }

  ngOnInit() {
    this.message = this.field.errors ? this.validateMessages.getMessageValidate(this.field) : null;
  }

}
