<div class="row mt-1 mb-1">
    <div class="d-flex justify-content-between">
        <div class=" d-flex flex-column flex-sm-row gap-2">
            <div>
                <label for="input-title" class="visually-hidden">Título</label>
                <input type="text"
                       class="form-control form-control-sm {{errors.title?'is-invalid':''}}"
                       [(ngModel)]="title" id="input-title" placeholder="Título">
            </div>
            <div>
                <label for="input-phone" class="visually-hidden">Teléfono</label>
                <input type="text"
                       class="form-control form-control-sm {{errors.phone?'is-invalid':''}}"
                       [(ngModel)]="phone" id="input-phone" placeholder="Teléfono">
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-success btn-sm" *ngIf="loading" [disabled]="loading">
                <div class="spinner-border spinner-border-sm"></div>
            </button>
            <button type="button" *ngIf="!loading && (title || phone)"
                    class="btn btn-success btn-sm"
                    (click)="savePhone()">
                <i class="mdi mdi-content-save"></i>
            </button>
            <button type="button" *ngIf="!loading && (title || phone)"
                    class="btn btn-info btn-sm ms-2"
                    (click)="clearPhoneData()">
                <i class="mdi mdi-broom"></i>
            </button>
        </div>
    </div>
    <div *ngIf="errors.phone || errors.title || errors.response" class="invalid-feedback" style="display: block">
        <div *ngFor="let message of errorMessages">
            <span>{{ message }}</span>
        </div>
    </div>
</div>
