<!-- start features -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="text-center mb-5">
                    <h2 class="mb-3 fw-semibold lh-base">Create and Sell Your NFTs</h2>
                    <p class="text-muted">The process of creating an NFT may cost less than a dollar, but the process of selling it can cost up to a thousand dollars. For example, Allen Gannett, a software developer.</p>
                </div>
            </div><!-- end col -->
        </div><!-- end row -->
        
        <div class="row">
            @for(data of featuresData;track $index){
            <div class="col-lg-3">
                <div class="card shadow-none">
                    <div class="card-body">
                        <img src="{{data.image}}" alt="" class="avatar-sm">
                        <h5 class="mt-4">{{data.title}}</h5>
                        <p class="text-muted">{{data.content}}</p>
                        <a href="javascript:void(0);" class="link-secondary fs-14">Read More <i class="ri-arrow-right-line align-bottom"></i></a>
                    </div>
                </div>
            </div><!--end col-->
        }
        </div><!--end row-->
    </div><!-- end container -->
</section><!-- end features -->