import { environment } from '../../environments/environment';

export const apiEnvironment = {
  url: environment.url,
};

export const catalogType = {
  TICKET_TYPE: 'TIPIFICACION',
  TICKET_CHANNEL: 'VIA INGRESO',
  TICKET_PRIORITY: 'PRIORIDAD',
  CATALOG_TYPE_BASE: 'BASE',
};
