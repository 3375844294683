import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig, FieldSelected } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-radio',
  template: `
      <div
              class="form-group"
              [formGroup]="group">
          <div class="d-flex">
              <label class="form-label">{{ config.label }}
                  <strong class="text-danger" *ngIf="config.required">*</strong>
              </label>
              <app-management-field *ngIf="editMode" [config]="config"
                                    (sendField)="sendFieldFunc($event)"></app-management-field>
          </div>
          <div *ngFor="let option of config.options" class="form-check form-check-inline">
              <input
                      [formControlName]="config.name"
                      data-name="{{config.name}}"
                      name="{{config.name}}"
                      class="form-check-input"
                      type="radio"
                      id="{{config.name}}_{{option.value}}"
                      value="{{option.value}}">
              <label class="form-check-label" for="{{config.name}}_{{option.value}}">{{ option.label }}</label>
          </div>
          <app-form-validate *ngIf="isSubmit && field.errors" [field]="field"></app-form-validate>
      </div>
  `
})

export class FormRadioComponent implements Field, OnInit {
  // @ts-ignore
  config: FieldConfig;
  // @ts-ignore
  group: FormGroup;
  // @ts-ignore
  isSubmit: boolean;
  // @ts-ignore
  field;
  // @ts-ignore
  editMode = false;

  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.field = this.group.controls[this.config.name];
  }

  sendFieldFunc(nameField: FieldSelected) {
    this.sendField.emit(nameField)
  }
}
