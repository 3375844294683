import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ClientService } from '../../../core/services/client.service';

@Component({
    selector: 'app-client-phone-form',
    templateUrl: './client-phone-form.component.html',
})
export class ClientPhoneFormComponent implements OnChanges {
    @Output() phoneAdded: any = new EventEmitter<any>();
    @Output() clearPhone: any = new EventEmitter<any>();

    @Input() idClient: any;
    @Input() oldPhone: any;
    phone: string = '';
    title: string = '';
    errors = {
        phone: false,
        title: false,
        response: false
    };
    errorMessages!: Array<any>;
    loading: boolean = false;

    constructor(private clientService: ClientService) {

    }

    ngOnChanges(): void {
        if (this.oldPhone) {
            this.phone = this.oldPhone.phone;
            this.title = this.oldPhone.title;
        }
    }

    clearPhoneData() {
        this.clearPhone.emit();
        this.phone = '';
        this.title = '';
    }

    savePhone() {
        this.errors = {
            phone: false,
            title: false,
            response: false
        };

        this.errorMessages = [];
        this.loading = true;

        if (!this.phone || !this.title) {
            this.errors = {
                phone: !this.phone,
                title: !this.title,
                response: false
            }
            if (!this.phone) this.errorMessages.push('El teléfono es requerido');
            if (!this.title) this.errorMessages.push('El título es requerido');
            this.loading = false;
            return;
        }

        const serviceMethod = this.oldPhone ?
            this.clientService.updatePhone(this.idClient, this.oldPhone.originPhone, this.phone.toString(), this.title) :
            this.clientService.addNewPhone(this.idClient, this.phone.toString(), this.title);

        serviceMethod.subscribe({
            next: (response: any) => {
                this.clearPhoneData();
                this.loading = false;
                this.phoneAdded.emit(response.phones);
            },
            error: (error: any) => {
                this.errors.response = true;
                this.loading = false;
                if (error.data?.length > 0 || error.error) {
                    this.errorMessages.push(error.data ? error.data[0] : error.error);
                } else {
                    this.errorMessages.push('Error al guardar el teléfono');
                }
            },
        });
    }
}
