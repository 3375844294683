import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig, FieldSelected } from '../../models/field-config.interface';

@Component({
  selector: 'app-form-textarea',
  template: `
    <div
      class="form-group"
      [formGroup]="group">
      <div class="d-flex">
        <label class="form-label">{{config.label}}
          <strong class="text-danger" *ngIf="config.required">*</strong>
        </label>
        <app-management-field *ngIf="editMode" [config]="config"
                              (sendField)="sendFieldFunc($event)"></app-management-field>
      </div>
      <textarea
        class="form-control"
        data-name="{{config.name}}"
        [ngClass]="isSubmit && field.errors?'is-invalid':''"
        [formControlName]="config.name"></textarea>
      <app-form-validate *ngIf="isSubmit && field.errors" [field]="field"></app-form-validate>
    </div>`
})
export class FormTextareaComponent implements Field, OnInit {
  // @ts-ignore
  config: FieldConfig;
  // @ts-ignore
  group: FormGroup;
  // @ts-ignore
  isSubmit: boolean;
  // @ts-ignore
  field;
  // @ts-ignore
  editMode = false;

  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    this.field = this.group.controls[this.config.name];
  }

  sendFieldFunc(nameField: FieldSelected) {
    this.sendField.emit(nameField)
  }

}
