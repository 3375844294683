import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiEnvironment } from '../../config/api.config';
import { processQueryParams } from '../libs/util';

@Injectable({
  providedIn: 'root',
})
export class ReportService {

  constructor(private http: HttpClient) {
  }

  getFilterData(topic: string, params?: string) {
    const paramsQuery = processQueryParams(params);
    return this.http.get<{ count: number; rows: any[] }>(
      `${apiEnvironment.url}/report/${topic}/filter-data`,
      {
        params: paramsQuery,
      }
    );
  }

  getFilesJob(topic: string) {
    // management-history
    return this.http.get<any>(`${apiEnvironment.url}/report/${topic}/process`);
  }

  getFile(id: string, topic: string) {
    return this.http.get<any>(`${apiEnvironment.url}/report/${topic}/file/${id}`);
  }

  createJobReport(model: any, topic: string) {
    console.log(topic);
    return this.http.post<any>(`${apiEnvironment.url}/report/${topic}`, model);
  }

  //Metabi
  getMetabaseDashboard() {
    return this.http.get<any>(`${apiEnvironment.url}/report/collection/dashboard-url`);
  }

  getMetabaseReport(name: string) {
    return this.http.get<any>(`${apiEnvironment.url}/report/collection/meta-url`, {
      params: {
        type: name
      }
    });
  }
}
