import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DynamicFormComponent } from '../../modules/dynamic-form/containers/dynamic-form/dynamic-form.component';

import { CollectionService } from '../../core/services/collection.service';
import { ToastService } from '../../core/services/toast.service';
import { DynamicManagementService } from '../../core/services/dynamic-management.service';
import { map } from 'underscore';

@Component({
  selector: 'app-collection-filters',
  template: `
    <div>
      <div class="card-body">
        <app-dynamic-form #mainFormComponent [config]="formModel.formModel" (change)="changeMainForm($event)"
                          (sendField)="changeMainForm($event)"></app-dynamic-form>
      </div>
    </div>`
})

export class CollectionFiltersComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('mainFormComponent', { static: false }) mainFormComponent: DynamicFormComponent | undefined;
  @Output() actionOnSuccess = new EventEmitter<any>();
  @Output() actionOnClose = new EventEmitter<any>();
  @Output() changeLoading = new EventEmitter<boolean>();
  @Output() changeFilters = new EventEmitter<any>();
  @Output() changeTotal = new EventEmitter<number>();
  @Input() campaignId!: string;
  @Input() config!: any;
  @Input() mainForm!: any;

  formModel: any;
  filters: any;
  triggersFromComponent: any = [];

  constructor(
    private collectionService: CollectionService,
    private dynamicManagementService: DynamicManagementService,
    public toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.formModel = this.dynamicManagementService.managementDetailModelForm(this.mainForm.inputs);
    if (this.formModel && this.formModel.triggers.length > 0) {
      map(this.formModel.triggers, (trigger: { from: any, field: any, action: any }) => {
        this.triggersFromComponent[trigger.field] = this.triggersFromComponent[trigger.field] || [];
        this.triggersFromComponent[trigger.field].push(trigger);
      });
    }
  }

  ngOnDestroy(): void {
    const filters = this.formatFilters();

    this.changeFilters.emit({
      filters,
      formValues: this.mainFormComponent?.value
    });
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.loadCountFilters();
    }, 100);
  }

  loadCountFilters() {
    this.changeLoading.emit(true);

    const filters = this.formatFilters();

    this.collectionService.countFilter(filters).subscribe({
      next: (data: any) => {
        this.changeTotal.emit(data.count);
        this.changeLoading.emit(false);
      },
      error: (err: any) => {
        console.log(err);
        this.changeLoading.emit(false);
        this.toastService.show(err, { classname: 'bg-danger text-white', delay: 15000 });
      }
    });
  }

  formatFilters() {
    this.filters = {
      campaignId: this.campaignId
    };
    return this.setFilterData(this.mainFormComponent?.value, this.filters);
  }

  setFilterData(data: any, toReturn: any) {
    let response = toReturn;
    console.log('data-setfilters', data);

    if (data['monthlyFeeAmountMin'] && data['monthlyFeeAmountMax'])
      response['monthlyFeeAmount'] = `between|${data['monthlyFeeAmountMin']},${data['monthlyFeeAmountMax']}`;
    if (data['daysPastDueMin'] && data['daysPastDueMax'])
      response['daysPastDue'] = `between|${data['daysPastDueMin']},${data['daysPastDueMax']}`;
    if (data['paymentDateMin'] && data['paymentDateMax'])
      response['paymentDate'] = `between|${data['paymentDateMin']} 00:00:00,${data['paymentDateMax']} 23:59:59`;
    if (data['document'])
      response['document'] = data['document'];
    if (data['syncId'])
      response['syncId'] = data['syncId'];
    if (data['fullName'])
      response['fullName'] = `like|%${data['fullName']}%`;
    if (data['paymentProbability'])
      response['paymentProbability'] = data['paymentProbability'];
    if (data['typeAssignment'])
      if (data['typeAssignment'] == 'notAssigned')
        response['ownerId'] = 'null|null';
    if (data['typeAssignment'] == 'assigned')
      response['ownerId'] = 'notnull|null';
    if (data['ownerId'] && data['ownerId'].length > 0)
      response['ownerId'] = `in|${data['ownerId'].join(',')}`;
    return response;
  }

  changeMainForm($event: any) {
    let name = $event.target?.name;
    let value = $event.target?.value;
    //radio button
    if (!name && $event.target?.id) {
      const [ nameInput, valueInput ] = $event.target.id.split('_');
      name = nameInput;
      value = valueInput;
    }
    if (this.triggersFromComponent && this.triggersFromComponent[name] && value) {
      map(this.triggersFromComponent[name], trigger => {
        trigger.action({
          model: this.formModel.formModel,
          result: value,
          form: this.mainFormComponent
        });
      });
    }
    this.loadCountFilters();
  }
}
