import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReportService } from '../../../core/services/report.service';
import { ToastService } from '../../../core/services/toast.service';
import { DynamicFormComponent } from '../../../modules/dynamic-form/containers/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-report-header',
  template: `
    <div class="card">
      <div class="card-body">
        <div class="row g-4">
          <div class="col-6 d-flex justify-content-sm-start">
            <app-dynamic-form #mainFormComponent [config]="form"
                              (change)="actionForm($event)"></app-dynamic-form>
          </div>
          <div class="col-6 d-flex align-items-end flex-column">
            <div class="mt-2">
              <!--                        <button *ngIf="isPending()" class ="btn btn-danger"-->
              <!--                                (click)="cancelJob()">-->
              <!--                          <i class="las la-trash text-white h5 m-0 p-0"></i>-->
              <!--                        </button>-->
              <button
                [ngClass]="{'disabled': loading || !mainFormComponent?.valid || isPending(), 'btn btn-secondary mx-2': true}"
                (click)="createFileJob()">
                Generar reporte
              </button>
              <button [ngClass]="{'disabled': !fileJobId, 'btn btn-success': true}"
                      (click)="downloadFile()">
                <i class="las la-download text-white h5 m-0 p-0"></i>
              </button>
            </div>
            <div class="mt-2">
              <span *ngIf="!loading && !fileJobId && !isPending()">
                <i class="las la-times-circle h5 text-danger"></i> Archivo no encontrado
              </span>
              <div class="d-flex align-items-center" *ngIf="loading || isPending()">
                <span>{{ progress }}% </span>
                <div class="progress p-0 me-2 ms-1" style="width: 8vh">
                  <div class="progress-bar progress-bar-striped progress-bar-animated"
                       role="progressbar" aria-valuenow="75"
                       aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                </div>
                <span class="ml-2">Creando archivo</span>
              </div>
              <span *ngIf="!loading && fileJobId && !isPending()">
                <i class="las la-check-circle h5 text-success"></i> Listo para descargar
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>`,
})

export class ReportHeaderComponent implements OnInit {
  @ViewChild('mainFormComponent', { static: false }) mainFormComponent: DynamicFormComponent | undefined;
  @Output() getFilters: EventEmitter<void> = new EventEmitter<void>();
  @Input() form!: any;
  @Input() topic!: string;
  @Input() filterData!: any;
  loading = false;
  fileJobId!: any;
  jobs!: any[];
  updateTableInterval!: any;
  progress!: string;


  constructor(private reportService: ReportService,
              public toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.loadFileJob();
  }

  //TODO: actualizar el proceso de pendiente como se hizo en la asignación
  isPending() {
    const maxJobId = this.jobs.reduce((max: any, current: any) => {
      return parseInt(current.jobId) > parseInt(max.jobId) ? current : max;
    }, this.jobs[0]);
    return !(maxJobId?.progress == 100 || !maxJobId);
  }

  loadFileJob() {
    this.loading = true;
    this.reportService.getFilesJob(this.topic).subscribe({
      next: (data) => {
        const maxJobId = data.reduce((max: any, current: any) => {
          return parseInt(current.jobId) > parseInt(max.jobId) ? current : max;
        }, data[0]);
        this.progress = maxJobId?.progress ?? '0';
        this.fileJobId = +this.progress == 100 ? maxJobId?.jobId : null;
        this.jobs = data;
        this.loading = false;
        this.initInterval();
      },
      error: (err: any) => this.errorFunction(err)
    });
  }

  initInterval() {
    if (!this.updateTableInterval && this.isPending()) {
      this.updateTableInterval = setInterval(() => {
        this.loadFileJob();
      }, 3000);
    } else if (!this.isPending()) clearInterval(this.updateTableInterval);
  }

  ngOnDestroy() {
    if (this.updateTableInterval) clearInterval(this.updateTableInterval);
  }

  cancelJob() {
    console.log('desea cancelar?');
  }

  createFileJob() {
    delete this.filterData.page;
    delete this.filterData.pageSize;
    this.progress = '0';
    this.loading = true;
    this.fileJobId = null;
    this.updateTableInterval = null;
    const filters = { ...this.filterData };
    if (filters.collectionCampaignId === 'all') {
      delete filters.collectionCampaignId;
    }
    this.reportService.createJobReport(this.convertData(filters), this.topic).subscribe({
      next: (data) => {
        console.log('se creó el job: ', data);
        this.loading = false;
        this.toastService.show('La creación del archivo está en proceso.', {
          classname: 'bg-success text-white',
          delay: 2000
        });
        this.loadFileJob();
      },
      error: (err) => this.errorFunction(err)
    });
  }

  downloadFile() {
    this.reportService.getFile(this.fileJobId, this.topic).subscribe({
      next: (data) => {
        const url = data.logs[0];
        window.open(`${url}`, `Download`);
      },
      error: (err) => this.errorFunction(err)
    });
  }

  errorFunction(err: any) {
    console.error(err);
    this.toastService.show(err, { classname: 'bg-danger text-white', delay: 2000 });
    this.loading = false;
  }

  actionForm(data: any) {
    this.mainFormComponent?.validate();
    this.getFilters.emit(data);
  }

  convertData(inputObj: any) {
    const outputObj: any = {};
    for (const key in inputObj) {
      if (typeof inputObj[key] === 'object' && 'min' in inputObj[key] && 'max' in inputObj[key]) {
        outputObj[key] = `max:${inputObj[key].max}&min:${inputObj[key].min}`;
      } else if (typeof inputObj[key] === 'object' && 'min' in inputObj[key]) {
        outputObj[key] = `min:${inputObj[key].min}`;
      } else if (typeof inputObj[key] === 'object' && 'max' in inputObj[key]) {
        outputObj[key] = `max:${inputObj[key].max}`;
      } else {
        outputObj[key] = inputObj[key];
      }
    }
    return outputObj;
  }

}
