import { apiEnvironment } from "../../config/api.config";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { ActiveCallModel } from "../models/call.model";

@Injectable({
  providedIn: 'root',
})
export class CallService {
  private currentCallSubject: BehaviorSubject<ActiveCallModel>;
  public currentCall: Observable<ActiveCallModel>;
  private hangupCallSubject: BehaviorSubject<any>;
  public hangupCall: Observable<any>;
  private makeCallSubject: BehaviorSubject<any>;
  public makeCall: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentCallSubject = new BehaviorSubject<ActiveCallModel>(null!);
    this.hangupCallSubject = new BehaviorSubject<any>(null!);
    this.makeCallSubject = new BehaviorSubject<any>(null!);
    this.currentCall = this.currentCallSubject.asObservable();
    this.hangupCall = this.hangupCallSubject.asObservable();
    this.makeCall = this.makeCallSubject.asObservable();
  }

  public setCurrentCall(value: ActiveCallModel = null!) {
    this.currentCallSubject.next(value);
  }
  public setHangupCall(value: any = null!) {
    this.hangupCallSubject.next(value);
  }
    public setMakeCall(value: any = null!) {
        this.makeCallSubject.next(value);
    }
  downloadCall(model: any) {
    return this.http.get<any>(`${apiEnvironment.url}/call/download-call/${model}`);
  }

  setExternalCall(phoneNumber: string) {
    return this.http.get(`${apiEnvironment.url}/call/set-external-call/${phoneNumber}`);
  }

  getAgentStatus() {
    return this.http.get(`${apiEnvironment.url}/call/get-agent-status`);
  }

  hangUpCall() {
    return this.http.get(`${apiEnvironment.url}/call/hangup-call`);
  }

  saveCallStatus(model: any) {
    return this.http.post(`${apiEnvironment.url}/call/external-call-status`, model);
  }

  getExternalCallStatus() {
    return this.http.get(`${apiEnvironment.url}/call/external-call-status`);
  }
}
