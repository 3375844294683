import { MatSnackBar } from "@angular/material/snack-bar";
import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-form-modal-confirm',
  template:`
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close" (click)="activeModal.close('exit')"></button>
      </div>
      <div class="modal-body text-center">
        <lord-icon src="https://cdn.lordicon.com/tdrtiskw.json" trigger="loop" colors="primary:#f06548,secondary:#f7b84b" style="width:120px;height:120px"></lord-icon>
        <div class="mt-4 pt-1 fs-15 mb-4">
          <h4>{{title}}</h4>
          <p class="text-muted mx-4 mb-0">{{body}}</p>
        </div>
        <div class="hstack gap-2 justify-content-end">
          <button type="button" class="btn btn-light"
                  (click)="activeModal.close('cancel')">Cerrar
          </button>
          <button type="button" class="btn w-sm btn-success" (click)="activeModal.close('success')">{{confirm}}</button>
        </div>
      </div>
    </div>`,
  providers: [MatSnackBar]
})

export class ModalConfirmComponent implements OnInit {
  @Input() confirm!: string;
  @Input() title!: string;
  @Input() body!: string;

  constructor(public activeModal: NgbActiveModal) {
  }
  ngOnInit(): void {}
}
