import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { JsonEditorComponent } from "../jsonEditor/jsonEditor.component";
import { ToastService } from "../../core/services/toast.service";
import { ConfigReturn } from "../../core/models/config-return.model";

@Component({
  selector: 'app-config-modal-catalog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Configuración de {{name}}</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
              (click)="activeModal.close('exit')"></button>

    </div>
    <form  class="tablelist-form" autocomplete="off" >
      <div class="modal-body">
        <div class="mb-3">
          <label for="code" class="form-label">Configuración JSON</label>
          <app-json-editor [data]="jsonData"></app-json-editor>
        </div>
        <div class="form-group">
          <label for="textJson">JSON en texto</label>
          <textarea class="form-control" id="textJson"
                    rows="3"
                    name="textJson"
                    [(ngModel)]="textJson"
          ></textarea>
          <button type="button mt-2" class="btn btn-light" (click)="setData()">Aplicar
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <div id="status" class="d-flex align-items-center justify-content-center" *ngIf="loading">
          <div class="spinner-border text-primary avatar-xs m-auto" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="hstack gap-2 justify-content-end">
          <button type="button" class="btn btn-light"
                  (click)="activeModal.close('cancel')">Cerrar
          </button>
          <button type="submit" class="btn btn-success" id="add-btn" (click)="onSubmit()" [disabled]="loading || !canSave">Guardar</button>
        </div>
      </div>
    </form>`,
  providers: [MatSnackBar]
})

export class ConfigModalComponent implements OnInit {
  @ViewChild(JsonEditorComponent) jsonEditor!: JsonEditorComponent;
  @Input() name: string = '';
  @Input() canSave: boolean = true;
  @Input() id!: any;
  @Input() form !: any;
  @Output() submitAction !: EventEmitter<any>;
  @Input() parentFunction!: (id: any) =>Promise<boolean>;

  loading = false;
  jsonData: any = {};
  textJson!: string;


  constructor(
    public activeModal: NgbActiveModal,
    public toastService: ToastService
  ) {
  }

  ngOnInit() {
    this.jsonData = this.form;
    this.textJson = JSON.stringify(this.jsonData);
  }

  setData(){
    this.jsonData = JSON.parse(this.textJson);
  }

  async onSubmit(){
    if(this.parentFunction){
      try{
        this.loading = true;
        const model: ConfigReturn = {
          id: this.id,
          config : this.jsonEditor.submit()
        }
        await this.parentFunction(model);
        this.loading = false;
        this.activeModal.close('success');
      }catch (err) {
        this.loading = false;
        console.error(err);
      }
    }
  }
}
