import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../models/field.interface';
import { FieldConfig, FieldSelected } from '../models/field-config.interface';

import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormSelectMultipleComponent } from './form-select-multiple/form-select-multiple.component';
import { FormCheckComponent } from './form-check/form-check.component';
import { FormRadioComponent } from './form-radio/form-radio.component';


const components = {
  input: FormInputComponent,
  select: FormSelectComponent,
  'select-multiple': FormSelectMultipleComponent,
  textarea: FormTextareaComponent,
  check: FormCheckComponent,
  radio: FormRadioComponent
};

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  // @ts-ignore
  @Input() config: FieldConfig;
  // @ts-ignore
  @Input() group: FormGroup;
  // @ts-ignore
  @Input() isSubmit: boolean;
  // @ts-ignore
  @Input() editMode = false;
  // @ts-ignore
  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();
  // @ts-ignore
  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {
  }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
      this.component.instance.isSubmit = this.isSubmit;
      this.component.instance.editMode = this.editMode;
    }
  }

  ngOnInit() {
    // @ts-ignore
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    // @ts-ignore
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    this.component.instance.isSubmit = this.isSubmit;
    this.component.instance.editMode = this.editMode;
    this.component.instance.sendField.subscribe((data: FieldSelected) => {
      this.sendField.emit(data);
    });
  }
}
