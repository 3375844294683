import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-card-title-value',
  template: `
    <div class="card card-animate">
      <div class="card-body">
        <div class="py-4 px-3">
          <h5 class="text-muted text-uppercase fs-13 mb-3">{{ title }}</h5>
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              <i class="{{icon}} fs-3 text-muted"></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <h2 class="mb-0">
                <app-count-to class="counter-value" [to]="countTo" [from]="from" [duration]="duration"></app-count-to>
              </h2>
              {{ sufix }}<br>
            </div>
          </div>
        </div>
      </div>
    </div>`
})

export class CardTitleValueComponent {
  @Input() countTo!: any;
  @Input() from: any = 500;
  @Input() duration: any = 1;
  @Input() title!: any;
  @Input() icon!: any;
  @Input() sufix: string = '';

  constructor() {
  }
}
