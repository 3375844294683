import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FieldConfig, FieldSelected } from '../../models/field-config.interface';

@Component({
  selector: 'app-management-field',
  template: `
    <a (click)="sendFieldFunc({name: config.name, action: 'edit'})">
      <i class="ms-2 ri-edit-circle-fill text-info"></i>
    </a>
    <a (click)="sendFieldFunc({name: config.name, action: 'delete'})">
      <i class="ms-2 ri-delete-bin-fill text-danger"></i>
    </a>
  `
})
export class ManagementFieldComponent {
  @Input()
    // @ts-ignore
  config: FieldConfig;

  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();


  constructor() {

  }

  sendFieldFunc(nameField: FieldSelected) {
    this.sendField.emit(nameField)
  }


}
