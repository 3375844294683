import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import JSONEditor from "jsoneditor";

@Component({
  selector: 'app-json-editor',
  template: `
    <div>
      <div id="jsoneditor" class="jsonEditor"></div>
    </div>
  `
})
export class JsonEditorComponent implements OnInit, OnChanges{
  @Input() data: any = {};
  editor!: any;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.['data']){
      this.data = changes['data'].currentValue;
      this.setJsonValue();
    }
  }

  ngOnInit(): void {
    this.setJsonValue();
  }

  setJsonValue(){

    this.editor?.destroy();

    const container: any = document.getElementById("jsoneditor");

    const options = {}
    this.editor = new JSONEditor(container, options);

    this.editor.set(this.data);
  }

  submit() {
    return this.editor.get();
  }
}
