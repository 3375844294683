import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbDropdownModule,
  NgbToast,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDatepickerModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';

// Swiper Slider
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Counter
import { CountUpModule } from 'ngx-countup';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ClientLogoComponent } from './landing/index/client-logo/client-logo.component';
import { ServicesComponent } from './landing/index/services/services.component';
import { CtaComponent } from './landing/index/cta/cta.component';
import { DesignedComponent } from './landing/index/designed/designed.component';
import { PlanComponent } from './landing/index/plan/plan.component';
import { FaqsComponent } from './landing/index/faqs/faqs.component';
import { ReviewComponent } from './landing/index/review/review.component';
import { CounterComponent } from './landing/index/counter/counter.component';
import { WorkProcessComponent } from './landing/index/work-process/work-process.component';
import { TeamComponent } from './landing/index/team/team.component';
import { ContactComponent } from './landing/index/contact/contact.component';
import { FooterComponent } from './landing/index/footer/footer.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { LandingScrollspyDirective } from './landingscrollspy.directive';

// NFT Landing
import { MarketPlaceComponent } from './landing/nft/market-place/market-place.component';
import { WalletComponent } from './landing/nft/wallet/wallet.component';
import { FeaturesComponent } from './landing/nft/features/features.component';
import { CategoriesComponent } from './landing/nft/categories/categories.component';
import { DiscoverComponent } from './landing/nft/discover/discover.component';
import { TopCreatorComponent } from './landing/nft/top-creator/top-creator.component';

// Job Landing
import { ProcessComponent } from './landing/job/process/process.component';
import { FindjobsComponent } from './landing/job/findjobs/findjobs.component';
import { CandidatesComponent } from './landing/job/candidates/candidates.component';
import { BlogComponent } from './landing/job/blog/blog.component';
import { JobcategoriesComponent } from './landing/job/jobcategories/jobcategories.component';
import { JobFooterComponent } from './landing/job/job-footer/job-footer.component';
import { ToastsContainer } from './toasts/toasts-container.component';
import { ModalDeleteComponent } from './modals/modal-delete.component';
import { ModalConfirmComponent } from './modals/modal-confirm.component';
import { JsonEditorComponent } from './jsonEditor/jsonEditor.component';
import { CollectionFiltersComponent } from './collection-filters/collection-filters.component';
import { CallModalComponent } from './modals/call-modal/call-modal.component';
import { LoadButtonComponent } from './load-button/load-button.component';
import { ReportHeaderComponent } from './layouts/report-header/report-header-component';
import { FooterTotalAndButtonComponent } from './footer-total-and-button/footer-total-and-button.component';
import { ConfigModalComponent } from './modals/config-modal.component';
import { ToastTimerComponent } from './toast-timer/toast-timer.component';
import { CardTitleValueComponent } from './card-title-value/card-title-value.component';
import { ClientPhoneFormComponent } from './components/client-phone-form/client-phone-form.component';
import { RangeDatepickerInputComponent } from './components/range-datepicker-input/range-datepicker-input.component';
import { DynamicFormModule } from '../modules/dynamic-form/dynamic-form.module';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeneralComponentsModule } from './general/general-components.module';


@NgModule({
  declarations: [
    BreadcrumbsComponent,
    ClientLogoComponent,
    ServicesComponent,
    CtaComponent,
    DesignedComponent,
    PlanComponent,
    FaqsComponent,
    ReviewComponent,
    CounterComponent,
    WorkProcessComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    MarketPlaceComponent,
    WalletComponent,
    FeaturesComponent,
    CategoriesComponent,
    DiscoverComponent,
    TopCreatorComponent,
    ProcessComponent,
    FindjobsComponent,
    CandidatesComponent,
    BlogComponent,
    JobcategoriesComponent,
    JobFooterComponent,
    ToastsContainer,
    ModalDeleteComponent,
    ModalConfirmComponent,
    JsonEditorComponent,
    LoadButtonComponent,
    CollectionFiltersComponent,
    CallModalComponent,
    ReportHeaderComponent,
    FooterTotalAndButtonComponent,
    ConfigModalComponent,
    ToastTimerComponent,
    ConfigModalComponent,
    CardTitleValueComponent,
    ClientPhoneFormComponent,
    RangeDatepickerInputComponent,
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    SlickCarouselModule,
    NgSelectModule,
    CountUpModule,
    DynamicFormModule,
    NgxDatatableModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbToast,
    FormsModule
  ],
  exports: [
    NgSelectModule,
    GeneralComponentsModule,
    NgbNavModule,
    NgxDatatableModule,
    NgbDatepickerModule,
    NgbAccordionModule,
    BreadcrumbsComponent,
    ClientLogoComponent,
    ServicesComponent,
    CtaComponent,
    DesignedComponent,
    PlanComponent,
    FaqsComponent,
    ReviewComponent,
    CounterComponent,
    WorkProcessComponent,
    TeamComponent,
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    WalletComponent,
    MarketPlaceComponent,
    FeaturesComponent,
    CategoriesComponent,
    DiscoverComponent,
    TopCreatorComponent,
    ProcessComponent,
    FindjobsComponent,
    CandidatesComponent,
    BlogComponent,
    JobcategoriesComponent,
    JobFooterComponent,
    ToastsContainer,
    ModalDeleteComponent,
    ModalConfirmComponent,
    JsonEditorComponent,
    LoadButtonComponent,
    CollectionFiltersComponent,
    CallModalComponent,
    ReportHeaderComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbPaginationModule,
    FooterTotalAndButtonComponent,
    ConfigModalComponent,
    CardTitleValueComponent,
    ConfigModalComponent,
    ToastTimerComponent,
    ClientPhoneFormComponent,
    RangeDatepickerInputComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
}
