import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FieldConfig, FieldSelected } from '../../models/field-config.interface';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-check',
  template: `
      <div *ngIf="config" class="form-check form-switch form-switch-right form-switch-md"
           [formGroup]="group">
          <label class="form-check-label" for="flexSwitchCheckChecked">
              {{ config.label }}
              <strong class="text-danger" *ngIf="config.required">*</strong>
          </label>
          <input
                  data-name="{{config.name}}"
                  [hidden]="config.hide"
                  [formControlName]="config.name"
                  [ngClass]="isSubmit && field.errors?'is-invalid':''"
                  class="form-check-input"
                  type="checkbox" role="switch" checked>
          <app-form-validate *ngIf="isSubmit && field && field.errors" [field]="field"></app-form-validate>
      </div>
  `,
})
export class FormCheckComponent implements Field, OnInit {
  // @ts-ignore
  config: FieldConfig;
  // @ts-ignore
  group: FormGroup;
  isSubmit = false;
  // @ts-ignore
  field: AbstractControl;
  // @ts-ignore
  editMode = false;

  @Output() sendField: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.config) {
      this.field = this.group?.controls[this.config.name];
    }
  }

  sendFieldFunc(nameField: FieldSelected) {
    this.sendField.emit(nameField);
  }
}
